@import '../stylesheets/vars.scss';
@import url(//fonts.googleapis.com/earlyaccess/notosanskannada.css);

.greeting {
  background: $background;
  text-align: center;
  min-height: 100%;
  font-family: 'Noto Sans Kannada', sans-serif;
}

.greeting-header {
  padding: 20px;
  color: white;
}

.greeting-title {
  font-size: 1.5em;
}

.greeting-intro {
  font-size: large;
}

.greeting-message-block {
  margin-top: 10px;
  background: rgba(255, 255, 255, .7);
  padding: 15px;
}

.greeting-message-block h2 {
  display: inline-block;
  margin-right: 5px;
}

.greeting .input input, textarea {
  margin-top: 5px;
  height: 40px;
  padding: 5px 10px;
  box-sizing: border-box;
  font-size: 15px;
  border-radius: 3px;
  border: none;
}

.greeting .input textarea {
  width: 100%;
  height: 5em;
  resize: vertical;
}

.greeting-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: left;
  color: #444;
  box-sizing: border-box;
  padding: 10px;
}

.slideshow-container {
  position: relative;
  margin: auto;
  border: 3px solid $orange;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.img-window {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: black;

  &.upload {
    background: $orange;
  }

  .layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: 0;
    filter: blur(2px);
    opacity: .7;
  }

  .fade {
    -webkit-animation: fade 0.5s;
    animation: fade 0.5s;
  }

  .hidden {
    display: none;
  }

  img {
    border: 2px solid white;
    max-width: 100%;
    max-height: 300px;
    object-fit: cover;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    z-index: 0;
  }

  .upload-field {
    width: 90%;
    height: 90%;
    border: 1px dashed white;
    text-align: center;
    color: white;
    padding-top: 110px;
    cursor: pointer;
  }
}

@-webkit-keyframes fade {
  from { opacity: 0.4 }
  to { opacity: 1 }
}

@keyframes fade {
  from { opacity: 0.4 }
  to { opacity: 1 }
}

.tabs-view {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: space-between;

  .pick {
    color: $orange;
    display: block;
    border: 3px solid $orange;
    border-bottom: 0;
    border-radius: 5px 5px 0 0;
    padding: 8px 10px;
    width: calc(50% - 5px);
    text-align: center;
    cursor: pointer;

    &.active {
      background: $orange;
      color: white;
      transition: background .5s;
    }
  }  
}

.btn {
  padding: 10px 20px;
  background: $orange;
  border: none;
  border-radius: 3px;
  color: white;
  display: block;
  margin: 10px auto 0;
  cursor: pointer;
  outline: none;
  width: 100%;
  font-size: 16px;
  box-shadow: none;

  &:hover {
    opacity: .8;
    transition: opacity .5s;
  }

  &:disabled {
    opacity: 0.7;
  }
}

.link {
  color: white;
  display: block;
  text-align: center;
  margin-top: 20px;
}

.loading-img {
  height: 17px;
  margin-right: 10px;
}