body {
  padding: 0;
  font-family: sans-serif;
  margin: 0;
  overflow-x: hidden;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: Poppins, Arial;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  box-sizing:border-box
}
