@import '../stylesheets/vars.scss';
/**
 * Modify from https://codepen.io/chrishutchinson/pen/nafEp?editors=0100
 */
ul.card-stacks {
  list-style-type: none;
  padding: 0; margin: 20px;
  position: relative;
  cursor: pointer;
  li.title{ margin: 0 0 20px;
    h2 { font-weight: 700; }
  }
  li.stack {
    position: absolute;
    left: 0px;
    transition: all 0.4s cubic-bezier(.63,.15,.03,1.02);
    ul.cards-down {
      position: relative;
      list-style-type: none; margin: 0; padding: 0;
      li.card {
        transition: all 0.4s cubic-bezier(.63,.15,.03,1.22);
        left: 0px;
        transition-delay: 0.4s;
        background-color: transparent;
        height: 100px; width: 100px;
        position: absolute; top: 0px;
        perspective: 1000px;

        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
        img{
           max-width: 100%; height: auto;
        }
        div.inner {
          position: relative;
          height: 100%; width: 100%;
          transition: transform 0.4s;
          transform-style: preserve-3d;
          div.front, div.back {
            border-radius: 10px;
            box-shadow: 1px 2px 2px 0 #aaa;
            padding: 20px;
            position: absolute;
            height: 100%; width: 100%;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;

            justify-content: center;
            align-items: center;
            display: flex;
          }
          div.front {
            background: $background;
          }
          div.back {
            font-size: xx-large;
            background-color: $orange;
            transform: rotateY(180deg);
          }
          &.flip-transition {
            transform: rotateY(180deg);
          }
        }
        @for $i from 1 to 10 {
          &:nth-of-type(#{$i}) {
            z-index: $i + 2;
            transform: rotateZ((random(20) - 10) + deg);
          }
        }
      }
    }
  }

  &.expand-transition{
    li.stack {
      @for $i from 1 to 10 {
        &:nth-of-type(#{$i}) {
          left: (($i - 1) * 110) + px;
        }
      }
      ul.cards-down {
        li.card {
          @for $i from 1 to 10 {
            &:nth-of-type(#{$i}) {
              transform: rotateZ(0deg);
              top: (($i - 1) * 110) + px;
            }
          }
        }
      }
    }
  }

  // inspired by https://vineetgarg90.github.io/playing-cards/
  &.shuffle-transition {
    li.stack:first-of-type {
      ul.cards-down:first-of-type {
        li.card {
          @for $i from 1 to 10 {
            @keyframes shuffle-card_#{$i} {
              0% { left: 0px; z-index: $i; }
              50% { left: 110px; z-index: 11 - $i;}
              100% { left: 0px; z-index: $i; }
            }
            &:nth-of-type(#{$i}) {
              animation: shuffle-card_#{$i} 1.2s;
              animation-delay: (50 * $i) + ms;
              animation-iteration-count: 3;
            }
          }
        }
      }
    }
  }
}

.system-message {
  position: fixed;
  top: 0; left: 0; right: 0;
  color: white;
  font-size: larger;
  display: flex;
  justify-content: center;
  align-self: center;
}

.lottery-btns button {
  position: fixed;
  right: 50px;
  bottom: 50px;
  padding: 15px;
  background: $orange;
  display: inline-block;
  border: 4px double $orange;
  border-radius: 50px;
  font-size: larger;
  color: white;
  cursor: pointer;
  font-family: 'Noto Sans Kannada', sans-serif;
  &.hide {
    display: none;
  }
}

.home-link {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  background: black;
  text-decoration: none;
  cursor: pointer;
  color: white;
  justify-content: center;
  font-family: 'Noto Sans Kannada', sans-serif;
  z-index: 10;
}
