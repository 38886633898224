@import '../stylesheets/vars.scss';

.orange-font {
  color: $orange;
}

.gray-font {
  color: #333;
  font-size: small;
}

.container {
  background: black;
  min-height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
}

.container .loading {
  text-align: center;
  color: white;
  font-size: 25px;
  position: absolute;
  top: 300px;
  width: 100%;
}

.loading img {
  width: 100px;
  margin: 0 20px 0 0;
  vertical-align: middle;
}

.container img {
  flex-grow: 1;
  margin: 2px;
  object-fit: cover;
  max-width: 100%;
  max-height: 200px;
  filter: grayscale(70%);
}

.fade-in {
  animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.visible {
  visibility: visible;
  opacity: 1;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.message-link {
  position: fixed;
  bottom: 50px;
  left: 0;
  display: inline-block;
  padding: 15px;
  background: $orange;
  color: white;
  text-decoration: none;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  font-family: 'Noto Sans Kannada', sans-serif;
  z-index: 10;
}

.game-link {
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  background: black;
  text-decoration: none;
  cursor: pointer;
  color: white;
  justify-content: center;
  font-family: 'Noto Sans Kannada', sans-serif;
  z-index: 10;
}
